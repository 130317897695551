import React from 'react'
import ChooseUs from './ChooseUs'
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaStar } from "react-icons/fa";
import { FaTaxi } from "react-icons/fa";

const Look = () => {
    return (
        <div className='bg-[#d1d5db] py-10 sm:py-12 md:py-16 mt-4 px-5'>
            <div className='container  mx-auto'>
                <div className='flex items-center justify-center  flex-wrap md:flex-nowrap'>
                    <h3 className='text-[30px] sm:text-[34px] md:text-[36px] font-semibold'>WHY CHOOSE US</h3>
                </div>
                <div className='chooseus-cont'>
                   <ChooseUs 
                    icon={<RiCustomerService2Fill/>}
                    title="24x7 CUSTOMER SUPPORT"
                    desc="We Offer 24x7 Pickup Up And Drop Facility In Tamil Nadu To Facilitate The Customers Emergency Needs." />

                   <ChooseUs  
                   icon={<><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>}
                   title="TOP RATED DRIVER" 
                   desc="All Our Driver trainer To Delivery The Best Experience."/>

                   <ChooseUs 
                   icon={<FaTaxi/>}
                   title="CLEAN & SAFE CABS"
                   desc="Provide You Enormous Car Options For your One Way Taxi Booking Preference."/>
                </div>
            </div>
        </div>
    )
}

export default Look