import React from 'react'
import { FaStar } from "react-icons/fa";

const ReviewCard = ({name,comment,ratings}) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < ratings; i++) {
          stars.push(<FaStar/>);
        }
        return stars;
      };
  return (
    <div className='red'>
        <div className='review-card'>
            <span>{name.charAt(0)}</span>
            <h4>{name}</h4>
            <h3 className='review-rate'>{renderStars()}</h3>
            <p>{comment}</p>
          
        </div>
    </div>
  )
}

export default ReviewCard