import React from 'react'
import PriceCars from './PriceCars'
import suvcar from "../assets/images/suvCar.png";
import primesuv from "../assets/images/innovaCar.png"
import sedan from "../assets/images/sedanCar.png";
import tempo from "../assets/images/tempo.jpg"


import AirportTransferCar from './AirportTransferCar';

const AirportTransfer = () => {
    return (
        <div id="airport_transfer">
            <div className='bg-white-300' id='our_pricing'>
                <div className='container mx-auto px-5 py-10 md:py-20'>
                    <h5 className='text-[#fcb708] font-bold text-center'>TRANSFER</h5>
                    <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>AIRPORT TRANSFER</h3>
                    <h5 className='text-[#000] font-bold text-center text-[13px]'>ONLY AIRPORT TO CITY DROP / CITY TO AIRPORT DROP</h5>
                    <div className='container mx-auto px-5 mt-10 airport_transfer'>
                        <AirportTransferCar img={sedan} car_name="SEDAN" amountR="1000" />
                        <AirportTransferCar img={suvcar} car_name="SUV" amountR="1500" />
                        <AirportTransferCar img={primesuv} car_name="INNOVA" amountR="1800" />
                        <AirportTransferCar img={tempo} car_name="TEMPO" amountR="2500" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirportTransfer