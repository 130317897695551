import React from 'react'
import rameswaram from  "../assets/pageBanners/rameswaram.jpg"
import kannaykumari from "../assets/pageBanners/kanyakumari.png"
import thiruvan from "../assets/pageBanners/tiruvanandhapuram.jpg"
import kodai from "../assets/touristPlaces/kodaikanal.jpg"
import munnar from "../assets/pageBanners/munnar.jpeg"
import thekkady from "../assets/pageBanners/thekkady.jpg"
import TourPackageCard from './TourPackageCard'


const TourPackage = ({tour,pack ,description}) => {
  return (
    <div className='ex-package bg-gray-300'>
    <div className='container mx-auto px-5 py-10 md:py-20'>
    <h5 className='text-[#fcb708] font-bold text-center'>TOUR</h5>
     <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>{tour}</h3>
   
    {description ? <p className='text-[16px] sm:text-[17px] md:text-[19px] w-full sm:w-full md:w-[80%] mx-auto text-center'>{description}</p> : ""}
     
    <div className='container mx-auto px-5 mt-10  ex-card'>
      {
        pack.map(index =>{
          return( <TourPackageCard card_img={index.img} city={index.city} days={index.days} nights={index.nights} place={index.places}/>)
        })
      }
       
        
    </div>
    </div>
</div>
  )
}

export default TourPackage