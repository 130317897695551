import React from 'react'
import { Divider } from 'antd'
import { MdLocalPhone } from "react-icons/md";

const PriceCars = ({img , car_name ,amountR,amount,para }) => {
    return (
        <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 car-container'>
            <div className='ribbon'>
                <span>Popular</span>
            </div>
            <h3 className='car-title'>{car_name}</h3>
            <div className='pb-10'>
            <img src={img} alt="suvcar" className='w-[250px] mx-auto pt-3' />
            </div>
            <div className='trip-card'>
                {/*<p>One Way <span>₹{amount}</span>KM</p>*/}
              { amountR? (<p><span>₹{amountR}</span>KM</p>):(<p style={{fontWeight:"500"}}>{para}<br/></p>)}
              {para?(<p style={{fontWeight:"500"}}>Further Enquiry call Now</p>):""}
            </div>
            <Divider dashed style={{borderColor:"black"}}/>
            <div className='call-btn-cont'>
                 <a href='tel:+916380562390'>
                <button>Call Now  <MdLocalPhone/></button>
                </a>
            </div>
            
        </div>
    )
}

export default PriceCars