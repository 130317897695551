import React from 'react'
import { Divider } from 'antd'
import { MdLocalPhone } from "react-icons/md";
const AirportTransferCar = ({img , car_name ,amountR}) => {
  return (
    <div className='bg-white-300 py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 car-container box'>
          
            <h3 className='car-title'>{car_name}</h3>
            <div className='pb-10'>
            <img src={img} alt="suvcar" className='w-[250px] mx-auto pt-3' />
            </div>
            <div className='trip-card'>
                {/*<p>One Way <span>₹{amount}</span>KM</p>*/}
                <p><span>₹{amountR}</span></p>
            </div>
            <Divider dashed style={{borderColor:"black"}}/>
            <div className='call-btn-cont'>
                 <a href='tel:+916380562390'>
                <button>Call Now  <MdLocalPhone/></button>
                </a>
            </div>
            
        </div>
  )
}

export default AirportTransferCar