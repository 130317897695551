import React from "react";
import Popup from "./Popup";
import { useState } from "react";
import { day } from "../days";

const Daypackage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModel = () => {
    setModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = "auto";
  };
  return (
    <div className="mt-6 table-cont overflow-x-hidden">
      <div className=" flex">
        <caption>
          For travelling holiday packages, you can now reserve taxis for a
          continuous period of one to ten days.Estimated per day hours: 4:00 am
          to 10:00 pm.Additional fees apply after excess per hour and excess per
          kilometre.Exculded are Toll, Parking, State Permit, Night Allowance,
          and Hills Charges.
        </caption>
      </div>
      <div className=" overflow-x-auto">
      <table>
        <tr>
          <th>Days</th>
          {/* <th>Allowed KM</th>*/}
          <th>
            Mini (Rs.) <br /> 250Km Free/day
          </th>
          <th>
            Sedan (Rs.) <br /> 250Km Free/day{" "}
          </th>
          <th>
            SUV (Rs.) <br /> 300Km Free/day
          </th>
          <th>
            Innova <br /> 300Km Free/day
          </th>
          <th>
            Tempo <br /> 350Km Free/day
          </th>
        </tr>
        {day.map((item) => {
          return (
            <tr>
              <td>{item.days}</td>
              {/*<td>{item.days*250}</td>*/}
              <td>{item.days * 3050}</td>
              <td>{item.days * 3400}</td>
              <td>{item.days * 5300}</td>
              <td>{item.days * 5600}</td>
              <td>{item.days * 8700}</td>
            </tr>
          );
        })}
      </table>
      </div>
      <button className="book-btn" onClick={openModel}>
        Book Now
      </button>
      <Popup
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        fieldChange="dp"
        setModalIsOpen={setModalIsOpen}
      />
    </div>
  );
};

export default Daypackage;
