import React, { useEffect } from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";


const TourPackageCard = ({card_img,city,days ,nights,place}) => {
  const encodedCity = encodeURIComponent(city);

  return (
    <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 ex-cont flex flex-col'>
      <div className='pb-10 bg-red-300 ex-img' style={{backgroundImage:`url(${card_img})`}}></div>
      <div className='route-card1 h-full'>
     
        <h5 className='title'>{city} Tour Package</h5>
        <h5> {nights} Nights/{days} Days</h5>
        <h5> {place?(<span><FaLocationDot/></span>):""}{place}</h5>
        <a
                href={`https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : ${encodedCity}%0ADays :${nights} Nights /  ${days} Days`} target='__blank'
                className="bg-[#fcb708] w-full flex text-center justify-center items-center font-semibold mt-4 md:mt-6 text-black py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px]  uppercase hover:text-white hover:bg-black  transition-all ease-linear"
              >
                whataspp enquiry
              </a>
      </div>
    {/*<Divider dashed style={{borderColor:"black"}}/>
    <button>Book Now</button>*/}
</div>
  )
}

export default TourPackageCard