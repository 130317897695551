import React from "react";

const LookCall = ({content}) => {
  return (
    <div className="bg-[#f0f5f7] py-10 sm:py-12 md:py-16 px-5">
      <div className="container  mx-auto">
        <div className="flex items-center justify-center md:justify-between flex-wrap md:flex-nowrap">
          <h3 className="text-[30px] sm:text-[34px] md:text-[36px] font-semibold">
            {content}
          </h3>
          <a
            href="tel:+916380562390"
            className="bg-[#1f86ef] text-white py-3 md:py-2 lg:py-3 px-20 md:px-6 lg:px-20 rounded-md text-[18px] inline-block call_now_btn mt-5 mx-auto sm:mx-0 md:mt-0 w-full text-center sm:w-auto"
          >
            Call Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default LookCall;
