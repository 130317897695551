import React from "react";
import InternationalTourCard from "./InternationTourCard";
import thailand from "../assets/images/thailand.jpg";
import singapore from "../assets/images/singapore.jpg";
import veitnam from "../assets/images/veitnam.jpg";
import saudi from "../assets/images/saudi.jpg";
import dubai from "../assets/images/dubai.jpg";
import maldives from "../assets/images/maldives.jpg";
import andamon from "../assets/images/andaman.jpg";
import LookCall from "./LookCall";

const InternationalTour = ({ tour, pack, description ,content }) => {
  return (
    <>
      <div className="ex-package bg-gray-300">
        <div className="container mx-auto px-5 py-10 md:py-20">
          <h5 className="text-[#fcb708] font-bold text-center">TOUR</h5>
          <h3 className="text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center">
            {tour}
          </h3>
          {description ? (
            <p className="text-[16px] sm:text-[17px] md:text-[19px] w-full sm:w-full md:w-[80%] mx-auto text-center">
              {description}
            </p>
          ) : (
            ""
          )}
          <div className="container mx-auto px-5 mt-10 inter-cont">
            {pack.map((item) => {
              return (
                <InternationalTourCard img={item.img} country={item.place} />
              );
            })}
          </div>
        </div>
      </div>
      <LookCall content={content} />
    </>
  );
};

export default InternationalTour;
