import React from 'react'
import Rooms from './Rooms';

import img1 from '../assets/Rooms/img1.jpeg';
import img2 from '../assets/Rooms/img2.jpeg'
import img3 from '../assets/Rooms/img3.jpeg'
import img4 from '../assets/Rooms/img4.jpeg'
import img5 from '../assets/Rooms/img5.jpeg'
import img6 from '../assets/Rooms/img6.jpeg'
import img7 from '../assets/Rooms/img7.jpeg'
import img8 from '../assets/Rooms/img8.jpeg'
import img9 from '../assets/Rooms/img9.jpeg'
import img10 from '../assets/Rooms/img10.jpeg'
import img11 from '../assets/Rooms/img11.jpeg'
import img12 from '../assets/Rooms/img12.jpeg'
import BookingForm from './BookingForm';


const AccomadtionDetails = () => {
    return (
        <div className='ex-package bg-gray-300'>
            <div className='container mx-auto px-5 py-10 md:py-20'>
                <h5 className='text-[#fcb708] font-bold text-center'>ACCOMODATION</h5>
                <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>BOOK FRESH UP ROOMS & B2B ROOMS</h3>
                <div className='container mx-auto px-5 mt-10'>

                    <table className='acc-table1'>
                    <caption>FRESH UP ROOMS & WAITING ROOMS </caption>
                        <tr>
                            <th>HOURS</th>
                            <th>NUM PERSON</th>
                            <th>AMOUNT</th>
                        </tr>

                        <tr>
                            <td>02- HOURS</td>
                            <td>02</td>
                            <td>600</td>
                        </tr>

                        <tr>
                            <td>03- HOURS</td>
                            <td>03</td>
                            <td>900</td>
                        </tr>

                        <tr>
                            <td>04- HOURS</td>
                            <td>04</td>
                            <td>1200</td>
                        </tr>

                        <tr>
                            <td>05- HOURS</td>
                            <td>05</td>
                            <td>1500</td>
                        </tr>

                        <tr>
                            <td>06- HOURS</td>
                            <td>06</td>
                            <td>1800</td>
                        </tr>
                    </table>
                    <div className='rooms-cont'>
                        <Rooms img={img1}/>
                        <Rooms img={img2}/>
                        <Rooms img={img3}/>
                        <Rooms img={img4}/>
                        <Rooms img={img5}/>
                        <Rooms img={img6}/>
                    </div>

                    <table className='acc-table2'>
                    <caption>BUDGET FOR BEST & B2B ROOM STAY </caption>
                    <tr>
                        <th colSpan='2'>AMOUNT (Rs.)</th>
                        <th>RATINGS</th>
                    </tr>
                    <tr>
                        <td>1200</td>
                        <td>2500</td>
                        <td>Budget Hotels</td>
                    </tr>

                    <tr>
                        <td>3000</td>
                        <td>4500</td>
                        <td>2 Star Hotels</td>
                    </tr>

                    <tr>
                        <td>5000</td>
                        <td>6500</td>
                        <td >3 Star Hotels</td>
                    </tr>

                    <tr>
                        <td>7000</td>
                        <td>8500</td>
                        <td >4 Star Hotels</td>
                    </tr>

                    <tr>
                        <td>9000</td>
                        <td>16000</td>
                        <td >5 Star Hotels </td>
                    </tr>

                    </table>

                    <div className='rooms-cont'>
                        <Rooms img={img7}/>
                        <Rooms img={img8}/>
                        <Rooms img={img9}/>
                        <Rooms img={img10}/>
                        <Rooms img={img11}/>
                        <Rooms img={img12}/>
                    </div>

                    <BookingForm/>

                </div>
            </div>
        </div>
    )
}

export default AccomadtionDetails