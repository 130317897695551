import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import suvcar from "../assets/images/suvCar.png";
import PriceCars from './PriceCars';
import primesuv from "../assets/images/innovaCar.png"
import sedan from "../assets/images/sedanCar.png";
import primesedan from "../assets/images/etiosCar.png"
import tempo from "../assets/images/tempo.jpg"
import bus from "../assets/images/bus.jpg"
const Price = () => {

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2500 ,
    prevArrow: <></>, 
    nextArrow: <></> ,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className='bg-gray-300' id='our_pricing'>
      <div className='container mx-auto px-5 py-10 md:py-20'>
        <h5 className='text-[#fcb708] font-bold text-center'>TAXI RATES</h5>
        <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>OUTSTATION  TARIFF</h3>
        <h5 className='text-[#000] font-bold text-center text-[12px]'>Oneway minimum coverage:130 km <span className='text-[#fcb708]'>&</span> Roundtrip minimum coverage:250 km</h5>
       
        <div className='container mx-auto px-5 mt-10 price-card'>
       
            <PriceCars img={primesedan} car_name="MINI" cat_trip="ROUND-TRIP" amount="15" amountR="11" />
            <PriceCars img={sedan} car_name="SEDAN" cat_trip="ROUND-TRIP" amount="17"  amountR="12"/>
            <PriceCars img={suvcar} car_name="SUV" cat_trip="ROUND-TRIP" amount="31" amountR="16" />
            <PriceCars img={primesuv} car_name="INNOVA" cat_trip="ONE-WAY" amount="32"  amountR="17"/>
            <PriceCars img={tempo} car_name="TEMPO" cat_trip="ONE-WAY" amount="32"  amountR="" para="Tempo 12,14,18,22 Seater"/>
            <PriceCars img={bus} car_name="BUS" cat_trip="ONE-WAY" amount="32"  amountR="" para="Bus 25,35,45,55 Seater"/>
       
        </div>
      </div>
    </div>
  )
}

export default Price