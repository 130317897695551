import React from 'react'
import TouristPlacesGrid from './TouristPlacesGrid'
import Madurai from "../assets/touristPlaces/madurai1.jpeg"
import Thekkady from "../assets/touristPlaces/ooty1.jpeg"
import Moonar from "../assets/touristPlaces/munnar1.jpeg"
import Chennai from "../assets/touristPlaces/chennai.jpg"
import Banglore from "../assets/touristPlaces/banglore2.jpg"
import Coimbatore from "../assets/touristPlaces/coimbatore2.jpg"
import Rameshwaram from "../assets/touristPlaces/rameshwaram.jpeg"
import Kodai from "../assets/touristPlaces/kodaikanal.jpg"
import Thrivandram from "../assets/touristPlaces/yerkad1.jpeg"
import { Link } from 'react-router-dom'
import pondicherryImg  from '../assets/insight/pon3.jpg';
import cochinImg  from '../assets/insight/coc5.jpg';



const TouristPlaces = () => {
  return (
    <div id="our_packages">
    <div className='container mx-auto py-10 md:py-16 px-5'>
        <h1 className='tourist-grid-title'>South India Tourist Places We offer packages in these Places</h1>
        <p className='tourist-grid-desc'>Here the list of most populars <span className=' font-extrabold inline-block'>5 STAR</span> rating tours package and sightseeing in south india, We offer special package all these places at best cost.</p>
        <div className='container mx-auto px-5 mt-10 tourist-grid'>
            <Link to={'/madurai'}><TouristPlacesGrid grid_image={Madurai} city="madurai" places="7 Places"/></Link>
            <Link to={'/thekkady'}><TouristPlacesGrid grid_image={Thekkady} city="thekkady" places="8 Places"/></Link>
            <Link to={'/moonar'}> <TouristPlacesGrid grid_image={Moonar}   city="Moonar" places="14 Places"/></Link>
            <Link to={'/chennai'}><TouristPlacesGrid grid_image={Chennai}  city="chennai" places="10 Places"/></Link>
            <Link to={'/bangalore'}><TouristPlacesGrid grid_image={Banglore} city="bangalore" places="14 Places"/></Link>
            <Link to={'/coimbatore'}><TouristPlacesGrid grid_image={Coimbatore}city="coimbatore" places="8 Places"/></Link>
            <Link to={'/rameshwaram'}><TouristPlacesGrid grid_image={Rameshwaram} city="rameshwaram" places="15 Places"/></Link>
            <Link to={'/kodaikanal'}><TouristPlacesGrid grid_image={Kodai}  city="Kodaikanal" places="15 Places"/></Link>
            <Link to={'/thiruvanathapuram'}><TouristPlacesGrid grid_image={Thrivandram} city="Thiruvanathapuram" places="6 Places"/></Link>
            <Link to={'/kanayakumari'}><TouristPlacesGrid grid_image={Moonar}  city="Kanyakumari" places="7 Places"/></Link>
            <Link to={'/pondicherry'}><TouristPlacesGrid grid_image={pondicherryImg}  city="Pondicherry" places="7 Places"/></Link>
            <Link to={'/cochin'}><TouristPlacesGrid grid_image={cochinImg}  city="Cochin" places="6 Places"/></Link>
            {/* <TouristPlacesGrid grid_image={ooty} city="OOTY" places="12 Places"/>
            <TouristPlacesGrid grid_image={allepey} city="Alleppey" places="6 Places"/> */}
        </div>
    </div>
    </div>
  )
}

export default TouristPlaces