import React from 'react'

const TouristPlacesGrid = ({grid_image,places,city}) => {
    const more="Lot of"
  return (
    <div  style={{ backgroundImage:`url(${grid_image})` }} className="grid-items">
             <div className='overlay'>
                <span>{places}</span>
                <div className='overlay-child'>
                    <h5 className='city'>{city}</h5>
                    <h5 className='places'>{!places?more:places} Awesome Places</h5>
                </div>
             </div>
    </div>
  )
}

export default TouristPlacesGrid