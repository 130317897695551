import React from 'react'
import DharshanCard from './DharshanCard'


const Dharshan = ({tour,pack,pack1 ,description}) => {
  return (
    <div className='ex-package bg-gray-300'>
        <div className='container mx-auto px-5 py-10 md:py-20'>
      <h5 className='text-[#fcb708] font-bold text-center'>TOUR</h5>
      <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>{tour}</h3>
      {description ? <p className='text-[16px] sm:text-[17px] md:text-[19px] w-full sm:w-full md:w-[80%] mx-auto text-center'>{description}</p> : ""}
      <div className='container mx-auto px-5 mt-10  d-cont'>
         {
            pack.map( i =>{
                return(<DharshanCard img={i.img} days={i.days} nights={i.nights} places={i.places}/>)
            })
         }
      </div>
     </div>
     <div className='container mx-auto px-5 py-10 md:py-10'>
      <h5 className='text-[#fcb708] font-bold text-center'>TOUR</h5>
      <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>NORTH INDIA DHARSHAN PACKAGE</h3>
      <div className='container mx-auto px-5 mt-10  d-cont'>
         {
            pack1.map( i =>{
                return(<DharshanCard img={i.img} days={i.days} nights={i.nights} places={i.places} packageName={i.packageName}/>)
            })
         }
      </div>
     </div>
    </div>
  )
}

export default Dharshan