import React from 'react'
import icon from "../assets/images/aajavologoimage.PNG";
import { Link } from 'react-router-dom';
import { IoLocation} from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import SocialMedia from './SocialMedia';


const Footer = ({setChangeLink}) => {
   
    return (
        <div className='bg-[#1a1a1a] pt-2 md:pt-4'>
            <div className='container mx-auto py-5 px-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-6 px-6 text-center'>
                    <div className='col-span-2 lg:col-span-1'>
                        <h3 className='text-[#e30d16] text-[22px] sm:text-[23px]  md:text-[24px] font-semibold'>PACKAGE  FROM TOP CITIES</h3>
                        <div className='text-white mt-3 flex flex-col'>
                            <a href="/madurai" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM MADURAI</a>
                            <a href="/rameshwaram" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM RAMESWARAM</a>
                            <a href="/kanayakumari" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM KANYAKUMARI</a>
                            <a href="/thekkady" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM THEKKADY</a>
                            <a href="/chennai" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM CHENNAI</a>
                            <a href="/coimbatore" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM COIMBATORE</a>
                            <a href="/bangalore" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM BANGALORE</a>
                            <a href="/thiruvanathapuram" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM THIRUVANATHAPURAM</a>
                            <a href="/kodaikanal" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM KODAIKANAL</a>
                            <a href="/moonar" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM MUNNAR</a>
                            <a href="/pondicherry" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM PONDICHEERY</a>
                            <a href="/cochin" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TOURS PACKAGE FROM COCHIN</a>
                        </div>
                    </div>
                    <div className=' col-span-2'>
                        <h3 className='text-[#e30d16] text-[22px] sm:text-[23px]  md:text-[24px] font-semibold uppercase'>South india tours package </h3>
                        <div className='text-white mt-3 uppercase flex flex-col'>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI - RAMESWARAM – KANYAKUMARI TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI - RAMESWARAM – KANYAKUMARI --THIRUVANATHAPURAM TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI - MUNNAR - THEKKADY - VAGAMON - AELLPPEY - VARKALA – COCHIN TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI - THEKKADY - MUNNAR - AELLPPEY – COCHIN TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI – KANAYAKUMARI -THIRUVANATHAPURAM - VARKALA - AELLPPEY – COCHIN TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI - UTHIRAGOSAIMANGAI - RAMESWARAM - DEVIPATTINAM  - KARAIKUDI - TANJAVUR- TRICHY TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI - TRICHY - THANJAVUR - KUMBAKONAM - CHIDHABARAM - THIRUVANAMALAI - KANCHIPURAM - CHENNAI - THIRUPATHI TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Coimbatore - Kodaikanal - Madurai - Rameswaram - Kanyakumari - Thiruvanthapuram Tour Package</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Coimbatore – ooty – kodaikanal – munnar – cochin tour package</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Bangaluru-Mysore, Coorg -Nagarhole, Bandipur -  Chikmagalur TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>THIRUVANTHAPURAM – KANYAKUMARI – RAMESWARAM – MADURAI TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI – TRICHY - THIRUVANAMALAI – KANCHIPURAM - KALAKASTHI – THIRUPATHI TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TRIUPATHI - CHENNAI – MAHABALIPURAM – KANCHIPURAM – CHIDABARAM – KUMBAKONAM – TANJAVUR – TRICHY – RAMESWARAM MADURAI - TOUR PACKAGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI – KODAIKANAL –THEKKADY –VAGAMON - MUNNAR – COCHIN TOUR PACAKGE</a>
                            <a href="/south-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>CAILCUT – ISHA YOGA – OOTY – KODAIKANL – MADURAI TOURS PACKAGE</a>
                        </div>
                    </div>

                    <div className='col-span-2 lg:col-span-1'>
                        <h3 className='text-[#e30d16] text-[22px] sm:text-[23px]  md:text-[24px] font-semibold'>INTERNATIONAL TOUR PACKAGE</h3>
                        <div className='text-white mt-3 flex flex-col'>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>THAILAND TOUR PACAKGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>SINGAPORE TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>VEITNAM TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>SAUDI TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>DUBAI TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MALDIVES TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>ANDAMON TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MALAYSIA TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>LAKSHADWEEP TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>BALI - INDONESIA TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>SRI LANKA TOUR PACKAGE</a>
                            <a href="/international-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MAURITIUS TOUR PACKAGE</a>
                        </div>
                    </div>
                    <div className=' col-span-2'>
                        <h3 className='text-[#e30d16] text-[22px] sm:text-[23px]  md:text-[24px] font-semibold uppercase'>North India Tour Package </h3>
                        <div className='text-white mt-3 uppercase flex flex-col'>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Jaipur - Bikaner - Jaisalmer - Jodhpur – Udaipur- RAJASTHAN TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Panjim - Bardez - Calangute – Canacona - GOA TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Ahmedabad - Vadodara - Ahmedabad-GUJARAT TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Ahmedabad - Dwarka - Somnath - Diu - Sasan Gir - Bhavnagar - Vadodara – Ahmedabad - GUJARAT TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Nainital - Binsar - Auli - Chopta -Rishikesh - UTTARAKHAND TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Jaipur - Bikaner - Jaisalmer - Jodhpur - Udaipur. UTTAR PRADESH TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Varanasi - Ayodhya - Praygaraj - Lucknow – Chitrakoot - UTTAR PRADESH TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Bodhgaya- Gaya - Patna - Rajgir - Nalanda - Deoghar UTTAR PRADESH TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Mathura - Vrindavan - Agra – Naimishanya- UTTAR PRADESH TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Gulmarg - Sonamarg - Pahalgam – Srinagar- JAMMU & KASHMIR TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Leh - Nubra Valley -Pangong Lake- LEH LADAKH TOUR PACKAGE </a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Ujjain - Omkareshwar - Maheshwar - Pachmarhi - Kanha –Khajuraho- MADHYA PRADESH TOUR PACKAGE </a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>Delhi - Shimla - Kufri - Manali - Gulaba - Dharamashala - Khajjari DulaHousie- HIMACHAL TOUR PACKAGE </a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>ASSAM & MEGHALAYA TOUR PACKAGE</a>
                            <a href="/north-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>PUNJAB & HARYANA TOUR PACKAGE</a>
                        </div>
                    </div>
                    <div className='col-span-2 lg:col-span-1'>
                        <h3 className='text-[#e30d16] text-[22px] sm:text-[23px]  md:text-[24px] font-semibold'>HONEY MOON TOUR PACAKAGE</h3>
                        <div className='text-white mt-3 flex flex-col'>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>SIKKIM TOUR PACKAGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>HIMACHAL TOUR PACKAGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>SHIMLA TOUR PACKAGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>KODAIKANAL TOUR PACAKGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TEKKADY TOUR PACAKGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>OOTY TOUR PACAKGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MUNNAR TOUR PACAKGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>KASHMIR TOUR PACAKGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MANIL TOUR PACAKGE</a>
                            <a href="/honeymoon-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>GOA TOUR PACAKGE</a>

                        </div>
                    </div>
                    <div className=' col-span-2'>
                        <h3 className='text-[#e30d16] text-[22px] sm:text-[23px]  md:text-[24px] font-semibold uppercase'>DHARSHA TOURS PACKAGES</h3>
                        <div className='text-white mt-3 uppercase flex flex-col'>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI RAMESWARAM KANYAKUMARI TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>MADURAI RAMESWARAM TANJAVUR KUMBAKONAM TRICHY TEMPLE DHARSHAN TOUR PACAKGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>COVAI MADURAI RAMESWARAM KANYUAKUMARI THIRUVANATHAPURAM TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>KUMBAKONAM NAVAGRAGHA TEMPLE DHARSAHN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TRIUPATHI TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>LORD MURUGAN KANTHASWAMY 06 HOUSE TEMPLE DHARSHAN TOUR PACAKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>CHOTTANIKARAI ERANAKULAM GURUVAYUR POLLACHI TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>TRICHY THIRUVANAMALAI KANCHIPURAM KALAKASTHI TRIUPATHI TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>SHIRDI TEMPLE  DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>UTTRAKHAND – HARIDWAR TEMPLE  DHARSHAN TOUR PACKAGE.</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>UTTAR PARDESH TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>GUJARAT TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>KASI TEMPLE DHARSHAN TOUR PACKAGE</a>
                            <a href="/darshan-tour-package" className='text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block'>KEDARNATHTEMPLE  DHARSHAN TOUR PACKAGE</a>
                        </div>
                    </div>
                    {/* <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Coimbatore</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Kochi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Bangalore</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Kochi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Madurai</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Trichy</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Salem</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Tirunelveli</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Kanyakumari</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Rameshwaram</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Kanyakumari taxi</p>
                        </div>
                    </div> */}
                </div>
                <div id="contact" className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mt-9 items-start gap-5 sm:gap-10 md:gap-16 lg:gap-20'>
                    <div>
                        <img src={icon} alt="foot_icon" className=' w-[180px] mx-auto bg-white' />
                        <p className='text-white pt-5 font-[16px]'>Best Travel, Tours and Car Services in Madurai, We offer special packages to the North Indians, Pilgirims, Honey Moon Couples and Others. We offer services in all over South India. One way, Two way and Packges Facilities Provided from Aajavo Travels.</p>
                    </div>
                    <div className='text-[#fff]'>
                        <h5 className='text-[21px] sm:text-[23px] md:text-[25px]'>Information</h5>
                        <ul className='pt-2 md:pt-5 text-[#fff] text-[14px] footer-dot-par'>
                            <li ><a href='/'>Home</a></li>
                            <li className='mt-1'><a href="#book_taxi_form">Booking</a></li>
                            <li className='mt-1'><a href="#about">About</a></li>
                            <li className='mt-1'><a href="#our_pricing">Our Pricing</a></li>
                            <li className='mt-1'><a href="/accomodation">Accomodation</a></li>
                            <li className='mt-1'><Link to="/terms-and-conditions" className=' cursor-pointer'>Terms & Conditions</Link></li>
                            <li className='mt-1'><Link to="/privacy-policy" className=' cursor-pointer' >Privacy Policy</Link></li>
                        </ul>
                    </div>
                    <div className='text-[#fff]'>
                        <h5 className='text-[21px] sm:text-[23px] md:text-[25px]'>Packages</h5>
                        <ul className='pt-2 md:pt-5 text-[#fff] text-[14px] footer-dot-par'>
                            <li className='mt-1'><a href="/domestic-tour-package">Domestic Tour Package</a></li>
                            <li className='mt-1'><a href="/international-tour-package">International Tour Package</a></li>
                            <li className='mt-1'><a href="/darshan-tour-package">Darshan Tour Package</a></li>
                            <li className='mt-1'><a href='/honeymoon-tour-package'>Honeymoon Tour Package </a></li>
                            <li className='mt-1'><a href='/north-tour-package'>North India Tour Package</a></li>
                            <li className='mt-1'><a href='/south-tour-package'>South India Tour Package</a></li>
                        </ul>

                        <div className=' text-white mt-5'>
                            <SocialMedia />         
                        </div>
                        <div className=' text-white mt-4'> 
                        <span className='mt-1'>GST : 33BOFPB1222K1ZJ</span>
                        </div>
                    </div>
                    <div className='text-[#fff]'>
                        <h5 className='text-[21px] sm:text-[23px] md:text-[25px]'>Official Info</h5>
                        <div className='pt-2 sm:pt-3 md:pt-4  sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Head office:</span>
                            <p className='text-[14px] flex gap-x-2 items-center'><span className=' text-[17px]'><IoLocation /></span><span>Madurai,Tamil Nadu, India</span></p>
                        </div>
                        <div className='pt-2 sm:pt-3 md:pt-4  sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Branch office:</span>
                            <p className='text-[14px] flex gap-x-2 items-center'><span className=' text-[17px]'><IoLocation /></span><span>Trichy, Coimbatore ,Bangalore, Chennai & Thiruvanthapuram, Cochin (Kerala).</span></p>
                        </div>
                        <div className='pt-2 sm:pt-3 md:pt-4 sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Email:</span>
                            <p className=' flex gap-x-2 items-center'>
                            <span><MdEmail /></span>
                            <span>
                            <p className='text-[14px]'><a href="mailto:travelsaajavo@gmail.com">travelsaajavo@gmail.com</a></p>
                            <p className='text-[14px]'><a href="mailto:aajavotravels@gmail.com">aajavotravels@gmail.com</a></p>
                            </span>
                            </p>
                        </div>
                        <div className='pt-2 sm:pt-3 md:pt-4 sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Phone:</span>
                            <p className=' flex gap-x-2 items-center'>
                            <span><FaPhoneAlt  /></span>
                            <span>
                            <p className='text-[14px]'><a href="tel:+919840426397">9840426397</a></p>
                            <p className='text-[14px]'><a href="tel:+916380562390">6380562390</a></p>
                            </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-white text-center  border-t-[1px] py-4 border-[#ffffff26] text-[14px] md:text-[15px] '>
                © Aajavo Travels All Rights Reserved || Designed By <a href='https://netbixzie.in/' target='__blank'>Netbix Technologies.</a>
            </div>
        </div>
    )
}

export default Footer