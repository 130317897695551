import React from "react";
import temp from "../assets/images/temp.jpg";
import Form from "./Form";
import banner1 from "../assets/images/banner1.jpg";

const Banner = () => {
  return (
    <div className="bg-gray-300  bg-no-repeat bg-cover bg-fixed" id="home" style={{backgroundImage:`url(${banner1})`}}>
      <div className="container mx-auto py-16 md:py-20 px-5" >
        <div className="flex flex-col justify-center items-center flex-wrap lg:gap-y-8 md:flex-nowrap gap-y-3  md:gap-y-2 gap-x-0  md:gap-x-3">
          <div className="w-[100%] md:w-[60%]">
            <Form />
          </div>
          <div className="">
          <h3 className="sm:w-[100%] md:w-[70%]  mx-auto text-[#000] mt-4 font-bold text-[23px] md:text-[25px] text-center bg-[#ffffff6b] md:bg-transparent">
          Most Trusted B2B Outstation Taxi, Tour Packages, Accomodation, Airport
          Taxi, Local Call Taxi Service in Madurai, Trichy, Coimbatore, Chennai,
          and Bangalore.
        </h3>
        <div className="sm:w-[100%] md:w-[70%] lg:w-[40%] mx-auto mt-3">
            <p className="text-[16px] sm:text-[17px] md:text-[19px]">
              Aajavo Travels provides a 24x7 cab service, airport taxi,
              outstation taxi, tour packages, hotel booking, and local call taxi
              service in Madurai, Trichy, Coimbatore, Chennai, and Bangalore
            </p>
            <p className="text-[16px] sm:text-[17px] md:text-[19px] mt-2">
              Book your hotels and taxis for Hassele-free in the travel tours of
              your choice.
            </p>
            <div className="text-center flex gap-0 md:gap-3 justify-between flex-col md:flex-row">
              <a
                href="#book_taxi_form"
                className="bg-[#fcb708] w-full md:w-1/2 flex justify-center items-center font-semibold mt-4 md:mt-6 text-black py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px]  uppercase hover:text-white hover:bg-black  transition-all ease-linear"
              >
                Book a Taxi
              </a>
              <a
                href="/accomodation"
                className="bg-[#fcb708] w-full md:w-1/2 flex justify-center items-center font-semibold mt-4 md:mt-6 text-black py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px] uppercase hover:text-white hover:bg-black  transition-all ease-linear"
              >
                Book Accommodation
              </a>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
