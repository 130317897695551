import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const DomesticTourCard = ({img,place,desc}) => {
  const navigate=useNavigate()
  const handleLink =(place)=>{
  place=="North India"? navigate('/north-tour-package'):navigate('/south-tour-package')
  }
  return (
  
    <div style={{ backgroundImage:`url(${img})` }} className='domestic-card' onClick={()=>handleLink(place)}>
        <div className='domestic-overlay'>
         <span> <FaLocationDot/> {place}</span>
         <div>
            <h4>{place} Tour Pacakge</h4>
            <p>{desc}</p>
         </div>
        </div>

    </div>

  )
}

export default DomesticTourCard