import React from 'react'
import InsightCard from './InsightCard'

const Insight = ({insight,head}) => {
  return (
    <div className='container mx-auto py-5 md:py-10 px-5'>
         <h5 className='text-[#fcb708] font-semibold text-center'>INSIGHT</h5>
        <h3 className='text-[#000] mt-4 font-bold text-[37px] sm:text-[42px] md:text-[50px] text-center'>{head} SIGHT SEEN</h3>
        <div className='container mx-auto px-5 mt-10  ex-card'>
         {
            insight && insight.map(index =>{
                return(<InsightCard card_img={index.img} place={index.places}/>)
            })
         }
        </div>
    </div>
  )
}

export default Insight