import React, { Fragment, useEffect, useRef, useState } from "react";
import About from "./About";
import Banner from "./Banner";
import Look from "./Look";
import Price from "./Price";
import { animateScroll as scroll } from "react-scroll";
import TouristPlaces from "./TouristPlaces";
import AirportTransfer from "./AirportTransfer";
import Reviews from "./Reviews";

const Home = () => {
  const [changeLink, setChangeLink] = useState("home");
  // alert(changeLink);
  useEffect(() => {
    if (changeLink) {
      scroll.scrollToTop({ smooth: false });
    }
  }, [changeLink]);

  const scrollRef = useRef(null);

  useEffect(() => {
    // After the component mounts, scroll to the "about" section on the Home page
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Fragment>
      <Fragment>
        <Banner />
        <About scrollRef={scrollRef} />
        <Price />
        <AirportTransfer />
        <TouristPlaces />
        <Look />
        <Reviews />
      </Fragment>
    </Fragment>
  );
};

export default Home;
