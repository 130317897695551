import React from 'react'

import { Divider } from 'antd';
import { FaLocationDot } from 'react-icons/fa6';
import { FaClock } from 'react-icons/fa';

const ExclusivePackageCard = ({card_img,routes ,days}) => {
  return (
    <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 ex-cont flex flex-col justify-between'>
        <div className='pb-10 bg-red-300 ex-img' style={{backgroundImage:`url(${card_img})`}}>
        </div>
        <div className='route-card'>
            <h5 className=' flex gap-x-2 items-center'><span><FaLocationDot/></span><span>{routes}</span></h5>
            <h5 className='mt-2 flex gap-x-2 items-center'><span><FaClock/></span><span>{days}</span></h5>
        </div>
         <a
                href={`https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : ${routes}%0Adays: ${days}`} target='__blank'
                className="bg-[#fcb708] w-full flex text-center justify-center items-center font-semibold mt-4 md:mt-6 text-black py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px]  uppercase hover:text-white hover:bg-black  transition-all ease-linear"
              >
                whataspp enquiry
              </a>
        {/*<Divider dashed style={{borderColor:"black"}}/>
        <button>Book Now</button>*/}
    </div>
  )
}

export default ExclusivePackageCard