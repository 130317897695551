import React from 'react'
import ReviewCard from './ReviewCard'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { review } from '../reviewsData'




const Reviews = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 1
              }
            }
          ]
      };

    return (
        <div className='bg-gray-300 pb-10' >
             <h5 className='text-[#fcb708] font-bold text-center'>REVIEWS</h5>
              <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>CUSTOMER REVIEWS</h3>
            <div className='container mx-auto py-5 md:py-10 px-5 review-cont'>
           
                <Slider  {...settings}>
                    {
                        review && review.map(client => {
                            return (<ReviewCard name={client.name} comment={client.comment} ratings={client.ratings} />)
                        })}
                </Slider>
            </div>



        </div>
    )
}

export default Reviews