import React from 'react'

const InsightCard = ({card_img,place}) => {
  return (
    <div className='py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 ex-cont'>
         <div className='pb-10 bg-red-300 ex-img' style={{backgroundImage:`url(${card_img})`}}>
        </div>
        <div className='route-card'>
            <h5>{place}</h5>
        </div>
    </div>
  )
}

export default InsightCard