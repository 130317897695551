import React from 'react'
import { FaLocationDot } from "react-icons/fa6";

const InternationTourCard = ({img,country}) => {
  return (
    <div className='inter-card' style={{ backgroundImage:`url(${img})` }}>
    <div className='inter-overlay'>
    <span><FaLocationDot/>{country}</span>
    </div>
    </div>
  )
}

export default InternationTourCard