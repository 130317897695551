const review = [{
    name: "Aravind Singhal",
    comment: `Mr. Babu of Aajavo Travels is an honest and
    wonderful person to deal with. He is not only very
    positive in his communication but also keeps his
    commitments, which demonstrates his honesty.
    He delivered whatever he promised. The driver
    was wearing mask throughout the trip, showing
    respect to us for our well-being. He was able to
    communicate in Hindi and English and also
    added value during this trip. Overall, I'd like to rate
    him 10 out of 10 and will use his services in
    future. Wishing Mr. Babu a great future ahead`,
    ratings: 5
},

{
    name: "Maneesha Gill",
    comment: `I had hired the vehicle Swift Dezire for my tour to
        Madurai, Rameshwar, Chettinad and back from 7
        to 10 February 2023. The response to all my
        queries towards booking process and rates etc
        were responded quickly and the price quoted was
        reasonable. The vehicle given to me was in a
        good condition and the Driver Azar was very
        polite and punctual, had a good knowledge of
        the places and we were were happy with his
        behaviour and services . I will definitely
        recommend your name to my friends and
        families in future.
        `,
    ratings: 5
},
{
    name: "Ashok Sabharwal",
    comment: `We rented a taxi from Ajaavo Travels to cover our
            trip starting from Madurai to Tirupati, finally
            ending up in Chennai. And what a wonderful
            experience it turned to be !! The driver Mr Hayath
            turned out to be a gem of a person - very polite,
            safe and deft driving skills and thoroughly
            knowledgeable about the geography of the area.
            He indeed was instrumental in making our trip
            lasting 10 days a memorable and enjoyable affair.
            Mr Baboo, the person managing this outft has
            been very nice and supportive too. Want to cover
            sight seeing places by road in this part of the
            country, I would at anytime recommend Ajaavo
            Travels.`,
    ratings: 5
}
    , {
    name: "Alka Malik",
    comment: `Amazing and extremely superb experience by Mr. Babu
                of Aajavo travels , I used the Cab Service for my elderly
                parents and their trip was extremely amazing and the
                driver partner Mr. Balu was superb...thank you and really
                appreciate your efforts..keep doing the great job..will
                definitely recommend and use the services in future too`,
    ratings: 5
},

{
    name: "Ravi R",
    comment: `My trip to Kerala was very good .our friends have booked
                    car from Madurai to Kerala. The driver was very polite
                    and friendly and we have enjoyed the place.special
                    thanks to Aajavo travels.`,
    ratings: 5
},

{
    name: "Yuva Thika",
    comment: `I booked for a car Aajavo travels, to visit Tiruchendur
                        Temple,with my family.from the time I booked it till the
                        time I reached back to madurai it was a good
                        experience.price also affordable good and safe
                        driving.thank you for Aajavo travels.`,
    ratings: 5
},

{
    name: "Muthuvalli SivaKumar",
    comment: `Had a great trip to madurai airport to rameshwaram and
                            kanyakumari poovar and Thiruvananthapuram
                            Padmanabha Swamy temple and Thiruvananthapuram
                            airport drop he was caring good and very cooperative
                            driver he helped us to explore many places very good
                            behaviour and friendly.enjoyed the trip thanks ajavo
                            travels madurai branch`,
    ratings: 5
},

{
    name: "Sourav Pathak",
    comment: `We booked Swift Dzire from Madurai to Rameshwaram
                                and Kanyakumari. Service was very good. You can trust
                                Mr Babu (Owner) without any thinking. Our holiday
                                experience got increased since we got good cab and
                                driver. Driver Senthil was good and very cooperative`,
    ratings: 5
},

{
    name: "Vikas Khandelwal",
    comment: `I have taken a cab from Aajavo travel drivers and tour
    partners are too polite. Their driver are professional and
    well trained in driving skills. Our driver named Hayat
    knows multiple languages, including hindi and English,
    with local languages. Cab charges are normal less than
    Ola and Uber.`,
    ratings: 5
},

{
    name: "K Mahalakshmi",
    comment: `Hi im maha from Hyderabad, actually i were
    booked a tempo from aajavo travel madurai for
    all South tamilnadu temples and sight seeing for
    my family more than 17 members, for 25 days
    suitable price and such a caring professional
    driver. And I'm so happy such nice travel agency.
    tqs for Mr baba owner of aajavo travel.. and to
    driver Karthick.`,
    ratings: 5
},
{
    name: "Nishant saha",
    comment: `Took Innova for round trip to Rameshwaram, driver
    assigned was on time, polite and gave suggestions on
    what all places to cover. Rates were also reasonable
    Highly recommend them for service.`,
    ratings: 5
},
{
    name: "Varun Singh",
    comment: `We booked our ride for madurai to rameshwaram and
    back.wonderful service from Abdul. would be booking
    next time with aajavo travels Abdul for any trip in that
    region.`,
    ratings: 5
}


]

export { review }