import React from 'react'
import "../table.css"
import Popup from './Popup';
import { useState } from 'react';
const Hourlypackage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [carAmount , SetCarAmount] =useState({
        mini:280,
        sedan:300,
        suv:400,
        innova:450
    })
    const openModel =()=>{
       setModalIsOpen(true); 
       document.body.style.overflow = 'hidden';
    }
    const closeModal = () => {
        setModalIsOpen(false);
        document.body.style.overflow = 'auto';
      };
    return (
        <div className='mt-6 table-cont overflow-x-hidden'>
        <div className=' flex'>
        <caption>Hourly package cannot be changed after booking confrimed.Additional fees apply after excess per hour and excess per kilometre.Exculded are Toll, Parking, Night Allowance, and Hills Charges.</caption>

        </div>
           <div className=' overflow-x-auto'>
           <table>
                <tr>
                    <th>Hrs</th>
                    <th>KM</th>
                    <th>Mini (Rs.)</th>
                    <th>Sedan (Rs.)</th>
                    <th>SUV (Rs.)</th>
                    <th>Innova  (Rs.)</th>
                </tr>

                <tr>
                    <td>1</td>
                    <td>10</td>
                    <td>{carAmount.mini}</td>
                    <td>{carAmount.sedan}</td>
                    <td>{carAmount.suv}</td>
                    <td>{carAmount.innova}</td>
                    </tr>

                

                <tr>
                    <td>2</td>
                    <td>20</td>
                    <td>{carAmount.mini+(280)}</td>
                    <td>{carAmount.sedan+(300)}</td>
                    <td>{carAmount.suv+(400)}</td>
                    <td>{carAmount.innova +(450)}</td>
                    </tr>

                <tr>
                    <td>3</td>
                    <td>40</td>
                    <td>{carAmount.mini+(280*2)}</td>
                    <td>{carAmount.sedan+(300*2)}</td>
                    <td>{carAmount.suv+(400*2)}</td>
                    <td>{carAmount.innova+(450*2)}</td></tr>

                <tr>
                <td>4</td>
                <td>60</td>
                <td>{(carAmount.mini)+(280*3)}</td>
                <td>{(carAmount.sedan)+(300*3)}</td>
                <td>{(carAmount.suv)+(400*3)}</td>
                <td>{(carAmount.innova)+(450*3)}</td></tr>

                <tr><td>5</td>
                <td>80</td>
                <td>{(carAmount.mini)+(280*4)}</td>
                <td>{(carAmount.sedan)+(300*4)}</td>
                <td>{(carAmount.suv)+(400*4)}</td>
                <td>{(carAmount.innova)+(450*4)}</td></tr>

                <tr><td>6</td>
                <td>100</td>
                <td>{(carAmount.mini)+(280*5)}</td>
                <td>{(carAmount.sedan)+(300*5)}</td>
                <td>{(carAmount.suv)+(400*5)}</td>
                <td>{(carAmount.innova)+(450*5)}</td></tr>

                <tr><td>7</td>
                <td>120</td>
                <td>{(carAmount.mini)+(280*6)}</td>
                <td>{(carAmount.sedan)+(300*6)}</td>
                <td>{(carAmount.suv)+(400*6)}</td>
                <td>{(carAmount.innova)+(450*6)}</td></tr>

                <tr><td>8</td>
                <td>140</td>
                <td>{(carAmount.mini)+(280*7)}</td>
                <td>{(carAmount.sedan)+(300*7)}</td>
                <td>{(carAmount.suv)+(400*7)}</td>
                <td>{(carAmount.innova)+(450*7)}</td></tr>

                <tr><td>9</td>
                <td>160</td>
                <td>{(carAmount.mini)+(280*8)}</td>
                <td>{(carAmount.sedan)+(300*8)}</td>
                <td>{(carAmount.suv)+(400*8)}</td>
                <td>{(carAmount.innova)+(450*8)}</td></tr>

                <tr><td>10</td>
                <td>180</td>
                <td>{(carAmount.mini)+(280*9)}</td>
                <td>{(carAmount.sedan)+(300*9)}</td>
                <td>{(carAmount.suv)+(400*9)}</td>
                <td>{(carAmount.innova)+(450*9)}</td></tr>

                <tr><td>11</td>
                <td>200</td>
                <td>{(carAmount.mini)+(280*10)}</td>
                <td>{(carAmount.sedan)+(300*10)}</td>
                <td>{(carAmount.suv)+(400*10)}</td>
                <td>{(carAmount.innova)+(450*10)}</td></tr>

                <tr><td>12</td>
                <td>220</td>
                <td>{(carAmount.mini)+(280*11)}</td>
                <td>{(carAmount.sedan)+(300*11)}</td>
                <td>{(carAmount.suv)+(400*11)}</td>
                <td>{(carAmount.innova)+(450*11)}</td></tr>
            </table>
           </div>
            <button className='book-btn'  onClick={openModel}>Book Now</button>
            
            <Popup
             isOpen={modalIsOpen}
             onRequestClose={closeModal}
             fieldChange="hp"/>
        </div>
    )
}

export default Hourlypackage