import React from "react";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io5";

const SocialMedia = () => {
  return (
    <>
      <h5 className="text-[21px] sm:text-[23px] md:text-[25px]">
        Follow Us On
      </h5>
      <div className=" flex gap-x-3 mt-2">
        <a href="https://www.facebook.com/Aajavotravels?mibextid=ZbWKwL" target="__blank">
          <IoLogoFacebook size={23} />
        </a>
        <a href="https://www.instagram.com/aajavotravels/?igsh=ZGZhZWNmbTQ5d3lh" target="__blank">
          <IoLogoInstagram size={23} />
        </a>
        <a href="https://www.youtube.com/@aajavotravels614" target="__blank">
          <IoLogoYoutube size={23} />
        </a>
        <a href="https://twitter.com/aajavo?t=I_yFlvaNc6yUcjzCu9__3A&s=09" target="__blank">
          <IoLogoTwitter size={23} />
        </a>
      </div>
    </>
  );
};

export default SocialMedia;
