import React, { useRef } from 'react'
import { Divider } from 'antd';
import { useForm } from 'react-hook-form';
import { useState} from 'react';

const BookingForm = () => {

    const [whatsaappMsg,setWhatsaappMsg]=useState('')
    const formRef =useRef(null)

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();

    const onSubmit = async (event)=>{

        trigger()
        event.preventDefault();
        await handleSubmit(async (data) => {
            const formData = new FormData(event.target);
            const formValues = Object.fromEntries(formData.entries());

            const handleValidation = () =>{
                if(formValues.full_name!=''  && formValues.email!='' && formValues.ph_no!='' &&  formValues.hotel_type!='' 
                && formValues.nop!='' && formValues.date!='' && formValues.msg!='')
                {
                    return true;
                }
                return false;
            }
                if(handleValidation())
                {
                
                    var front_url = "https://aajavotravels.in/";
                    const whatsaappMsg = "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " + formValues.full_name + " ,%0A%0AMobile Number  : +91" + formValues.ph_no + " ,%0A%0AEmail Address : " + formValues.email + "%0A%0AHotel Type : "+ formValues.hotel_type+"%0A%0ANo of Person : " + formValues.nop + "%0A%0A Date :" + formValues.date +"%0A%0AMessage :"+formValues.msg+" %0A%0AFor any questions please contact +916380562390 , +919840426397 %0A%0A"+front_url
                    setWhatsaappMsg(whatsaappMsg)
                    window.open("https://wa.me/+916380562390?text=" + whatsaappMsg);
                    formRef.current.reset();
                }
        })(event)
    }
  return (
    <div className='booking-form-cont'> 
        <form className='booking-form' onSubmit={onSubmit} ref={formRef}>
         <h4>Booking Rooms</h4>
         <Divider  style={{ borderColor: "rgb(193, 193, 193)", borderWidth:"1.5px" }} />
         <div className='input-container'>
            <div>
                <label>Name <span>*</span></label>
                <input type='text' placeholder='Enter Name' name='full_name '{...register('full_name', { required: true })}></input>
                {errors.full_name && <span className='mt-2 inline-block  bg-red-500 w-[75%] p-1 px-2 rounded-md text-white error'>Name is required.</span>}
            </div>
            <div>
                <label>Email <span>*</span></label>
                <input type='text' placeholder='Enter E-mail' name='email '{...register('email', { required: true })}></input>
                {errors.email && <span className='mt-2 inline-block  bg-red-500 w-[75%] p-1 px-2 rounded-md text-white error'>Email is required.</span>}
            </div>
            <div>
                <label>Mobile <span>*</span></label>
                <input type='text' placeholder='Enter Mobile number' name='ph_no'{...register('ph_no', { required: true })}></input>
                {errors.ph_no && <span className='mt-2 inline-block  bg-red-500 w-[75%] p-1 px-2 rounded-md text-white error'>Mobile Number is required.</span>}
            </div>
         </div> 

         <div className='input-container'>
            <div>
                <label>Hotel Type <span>*</span></label>
                 <select name='hotel_type'{...register('hotel_type', { required: true })}>
                    <option value="">Select Hotels</option>
                    <option value="Budget Hotels">Budget Hotels</option>
                    <option value="2 Star Hotels">2 Star Hotels</option>
                    <option value="3 Star Hotels">3 Star Hotels</option>
                    <option value="4 Star Hotels">4 Star Hotels</option>
                    <option value="5 Star Hotels">5 Star Hotels</option>
                 </select>
                 {errors.hotel_type && <span className='mt-2 inline-block  bg-red-500 w-[75%] p-1 px-2 rounded-md text-white error'>Hotel Type is required.</span>}
            </div>
            <div>
                <label>No of Person<span>*</span></label>
                <input type='text' placeholder='No of Persons'name='nop'{...register('nop', { required: true })}></input>
                {errors.nop && <span className='mt-2 inline-block  bg-red-500 w-[75%] p-1 px-2 rounded-md text-white error'>No of Person is required.</span>}
            </div>
            <div>
                <label>Date <span>*</span></label>
                <input type='date' placeholder='Pick Date' name='date '{...register('date', { required: true })}></input>
                {errors.date && <span className='mt-2 inline-block  bg-red-500 w-[75%] p-1 px-2 rounded-md text-white error'>Date is required.</span>}
            </div>
         </div>

         <div className='message-container'>
            <label>Message<span>*</span></label>
            <textarea placeholder='Message' rows='6'name='msg' {...register('msg', { required: true })}></textarea>
            {errors.msg && <span className='mt-2 inline-block  bg-red-500 w-[100%] p-1 px-2 rounded-md text-white '>Message is required.</span>}
         </div>

         <div className='booking-submit'>
            <button>SUBMIT</button>
         </div>

        </form>
    </div>
  )
}

export default BookingForm