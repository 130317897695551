import React from 'react'
import ExclusivePackageCard from './ExclusivePackageCard'
import rameswaram from  "../assets/pageBanners/rameswaram.jpg"
import kannaykumari from "../assets/pageBanners/kanyakumari.png"
import thiruvan from "../assets/pageBanners/tiruvanandhapuram.jpg"
import kodai from "../assets/touristPlaces/kodaikanal.jpg"
import munnar from "../assets/pageBanners/munnar.jpeg"
import thekkady from "../assets/pageBanners/thekkady.jpg"

const ExculsivePackages = () => {
  return (
    <div className='ex-package bg-gray-300'>
        <div className='container mx-auto px-5 py-10 md:py-20'>
        <h5 className='text-[#fcb708] font-bold text-center'>TOUR</h5>
         <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>EXCLUSIVE PACKAGES</h3>
        <div className='container mx-auto px-5 mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
            <ExclusivePackageCard card_img={rameswaram} routes="Rameshwaram" days="1N/2 Days"/>
            <ExclusivePackageCard card_img={rameswaram} routes="Rameshwaram" days="2N/3 Days"/>
            <ExclusivePackageCard  card_img={kannaykumari} routes="Rameshwaram to Kanyakumari" days="3N/4 Days"/>
            <ExclusivePackageCard  card_img={thiruvan} routes="Rameshwaram, kanayakumari, Thiruvanthapuram" days="4N/5 Days"/>
            <ExclusivePackageCard  card_img={kodai} routes="Kodaikanal" days="1N/2 Days"/>
            <ExclusivePackageCard  card_img={kodai} routes="Kodaikanal" days="2N/3 Days"/>
            <ExclusivePackageCard  card_img={munnar} routes="kodaikanal to Munnar" days="3N/4 Days"/>
            <ExclusivePackageCard  card_img={thekkady} routes="Kodaikanal, Thekkady, Munnar" days="4N/5 Days"/>
            <ExclusivePackageCard card_img={thekkady} routes="Thekkady, Munnar, Alleppey, Kochi" days="4N/5 Days"/>
            
        </div>
        </div>

    </div>
  )
}

export default ExculsivePackages