import React from 'react'
import about from "../assets/images/Screenshot_609-removebg-preview.png";
import { FaPhoneAlt } from "react-icons/fa";
import { Divider } from 'antd';



const About = () => {

    return (
        <div className='container mx-auto py-10 md:py-16 px-5' id='about'>
            <div className=' flex items-center gap-y-4  md:gap-y-0  gap-x-0 sm:gap-x-0 md:gap-x-36 flex-wrap md:flex-nowrap'>
                <div className='sm:w-[100%]  md:w-[40%] ' >
                    <img src={about} alt="about" />
                    <div className='call-info justify-center'>
                        <span><FaPhoneAlt/></span>
                        <div>
                            <p>Call Us 24/7 Anytime:</p>
                            <p>+91 63805 62390</p>
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-[60%]'>
                    <h5 className='text-[#fcb708] font-semibold'>ABOUT US</h5>
                    <h3 className='text-[#000] mt-4 font-bold text-[40px] sm:text-[45px] md:text-[50px]'>Welcome to Aajavo Travels</h3>
                    <p className='sm:mt-2 md:mt-4 w-full md:w-[80%]'>Aajavo provided the most trusted B2B tour packages: international tours, domestic tours, accommodation, hotels, resorts, intercity taxis, airport taxis, cab services, and local call taxi services in Madurai, Trichy, Coimbatore, Chennai, Bangalore, and Thiruvanathapuram, Cochin (Kerala).</p>
                    <p className='sm:mt-2 md:mt-4 w-full md:w-[80%]'>We offer customized packages to North Indians, South Indians, foreigners, Honey Moon couples, Internationals tours, Temple darshan tours, Pilgrims tours, Adventure tours and others travelers. </p>
                    <p className='sm:mt-2 md:mt-4 w-full md:w-[80%]'>"Best Travels in madurai, best tour operators in madurai, travel packages in madurai, best travel agency in madurai, cab booking madurai, hotel booking madurai, travel agencies in madurai, places to visit in madurai, madurai airport taxi, madurai travels tariff, madurai tourism packages, tamilnadu tourism madurai, madurai tours and travels, madurai travel agents tour operators, tours and travels madurai, taxi from madurai to munnar, tour operators in madurai, cabs in madurai, madurai cabs, cab services in madurai, hotels in madurai, madurai cabs tariff, tourist cabs in madurai, car rental in madurai, call taxi madurai, car hire in madurai, rent a car in madurai, taxi at madurai, tourist cabs in Trichy, taxi from madurai to Chennai, taxi from madurai to rameswaram, taxi from madurai to kanyakumari, taxi from madurai to coimbatore, taxi from madurai to thiruvanathapuram, taxi from madurai to bangalore, taxi from tirchy to madurai, oustation taxi from madurai, taxi from bangalore to madurai, taxi from chennai to madurai, taxi from madurai to cochin" .</p>
                    <p className='sm:mt-2 md:mt-4 w-full md:w-[80%]'>Make reservations for hotels and one-way, two-way, and intercity cab services to ensure a hassle-free travel experience. Our services are available throughout South India. </p>
                    <p className='sm:mt-2 md:mt-4 w-full md:w-[80%]'>Contact Us If you need tour packages, hotels, vehicles for rent or travels in the Best Way.</p>
                    <a href="#book_taxi_form" className='bg-[#fcb708] mt-6 text-black py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase book_taxi'><span className='inline-block '>Book Now</span></a>
                </div>
            </div>
        </div>
    )
}

export default About