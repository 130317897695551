import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";

const DharshanCard = ({img,days,nights,places,packageName}) => {
  

  return (
    <div className='d-pack'>
        
        <div className="d-img" style={{backgroundImage:`url(${img})`}}>  
        </div>
        <div className='d-info'>
            {packageName?(<h5>{packageName}</h5>):""}
            <p className='pack-route'><span><FaLocationDot/></span>{places}</p>
            <p className='pack-duration'> <span><FaClock/></span><span>{nights} Nights / {days} Days</span></p>
            <a
                href={`https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : ${places}%0ADays :${nights} Nights /  ${days} Days`} target='__blank'
                className="bg-[#fcb708] w-full flex text-center justify-center items-center font-semibold mt-4 md:mt-6 text-black py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px]  uppercase hover:text-white hover:bg-black  transition-all ease-linear"
              >
                whataspp enquiry
              </a>
        </div>
    </div>
  )
}

export default DharshanCard