import React from 'react'
import { useState } from 'react';
import Popup from './Popup';

const DayRentPackage = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModel =()=>{
       setModalIsOpen(true); 
       document.body.style.overflow = 'hidden';
    }
    const closeModal = () => {
        setModalIsOpen(false);
        document.body.style.overflow = 'auto';
      };

  return (
    <div className='mt-6 table-cont'>
    <table>
        <caption>Rental package: permitted 12 hours.Additional fees apply after excess per hour and excess per kilometre.Exculded are Toll, Parking, State Permit, Night Allowance, and Hills Charges.</caption>
        <tr>
            <th>Cab Type</th>
            <th>Base fare (Rs.)</th>
            <th>per km (Rs.)</th>
            
        </tr>

        <tr>
            <td>Mini</td>
            <td>1400</td>
            <td>9</td>
            
            </tr>

        <tr>
            <td>Sedan</td>
            <td>1500</td>
            <td>10</td>
           
            </tr>

        <tr>
            <td>Suv</td>
            <td>2000</td>
            <td>11</td>
           
           </tr>

        <tr><td>Innova</td>
            <td>2300</td>
            <td>12</td>
            </tr>

        <tr><td>Tempo</td>
            <td>2800</td>
            <td>18</td>
            </tr>


  
    </table>
    <button className='book-btn'  onClick={openModel}>Book Now</button>
    <Popup
     isOpen={modalIsOpen}
     onRequestClose={closeModal}
     fieldChange="Dp"/>
</div>
  )
}

export default DayRentPackage