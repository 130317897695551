import React, { useState } from "react";
import OneWay from "./OneWay";
import Roundtrip from "./Roundtrip";
import Hourlypackage from "./Hourlypackage";
import Daypackage from "./Daypackage";
import DayRentPackage from "./DayRentPackage";

const Form = () => {
  const [fromChange, setFormChange] = useState("oneway");
  function changeFroms(e) {
    setFormChange(e.target.value);
  }
  return (
    <div id="book_taxi_form">
      <div className="container mx-auto ">
      
        <div className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-y-4 justify-items-center py-3 pb-5 bg-white rounded-md shadow-lg items-center">
            <label
              className={`font-semibold hover:text-[#000] py-2 ${
                fromChange == "oneway" ? "text-white form_shadow" : ""
              } cursor-pointer`}
              htmlFor="oneway"
              onClick={changeFroms}
            >
              One Way
              <input
                type="radio"
                name="type"
                id="oneway"
                value="oneway"
                className="w-0 h-0"
              />
            </label>
            <label
              className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${
                fromChange == "round" ? "text-white form_shadow" : ""
              }`}
              htmlFor="round"
              onClick={changeFroms}
            >
              Round Trip
              <input
                type="radio"
                name="type"
                id="round"
                value="round"
                className="w-0 h-0"
              />
            </label>

            <label
              className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${
                fromChange == "HourlyPackage" ? "text-white form_shadow" : ""
              }`}
              htmlFor="HourlyPackage"
              onClick={changeFroms}
            >
              Hourly Packages
              <input
                type="radio"
                name="type"
                id="HourlyPackage"
                value="HourlyPackage"
                className="w-0 h-0"
              />
            </label>

            <label
              className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${
                fromChange == "DayRentPackage" ? "text-white form_shadow" : ""
              }`}
              htmlFor="DayRentPackage"
              onClick={changeFroms}
            >
              Rental Packages
              <input
                type="radio"
                name="type"
                id="DayRentPackage"
                value="DayRentPackage"
                className="w-0 h-0"
              />
            </label>

            <label
              className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${
                fromChange == "DayPackage" ? "text-white form_shadow" : ""
              }`}
              htmlFor="DayPackage"
              onClick={changeFroms}
            >
              Day Packages
              <input
                type="radio"
                name="type"
                id="DayPackage"
                value="DayPackage"
                className="w-0 h-0"
              />
            </label>
          </div>
        </div>
        {fromChange == "oneway" ? (
          <OneWay />
        ) : fromChange == "round" ? (
          <Roundtrip />
        ) : fromChange == "HourlyPackage" ? (
          <Hourlypackage />
        ) : fromChange == "DayPackage" ? (
          <Daypackage />
        ) : (
          <DayRentPackage />
        )}
      </div>
    </div>
  );
};

export default Form;
