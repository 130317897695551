import React from "react";
import DomesticTourCard from "./DomesticTourCard";
import north from "../assets/images/tamilnadu-tour.jpg";
import south from "../assets/images/kerala-tour.jpg";
import LookCall from "./LookCall";

const DomesticTourPackage = ({ tour, description }) => {
  return (
    <>
    <div className="ex-package bg-gray-300">
      <div className="container mx-auto px-5 py-10 md:py-20">
        <h5 className="text-[#fcb708] font-bold text-center">TOUR</h5>
        <h3 className="text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center">
          {tour}
        </h3>
        {description ? (
          <p className="text-[16px] sm:text-[17px] md:text-[19px] w-full sm:w-full md:w-[80%] mx-auto text-center">
            {description}
          </p>
        ) : (
          ""
        )}
        <div className="container mx-auto px-5 mt-10 domestic-cont">
          <DomesticTourCard
            img={north}
            place="North India"
            desc="We provide the best tour packages in surrounding the North India.Particularly Goa, Kashmir, Gujarat and etc.."
          />
          <DomesticTourCard
            img={south}
            place="South India"
            desc="We provide the best tour packages in surrounding the South India.Particularly Madurai, Kodaikanal, Rameshwaram and etc.."
          />
        </div>
      </div>
    </div>
    <LookCall content="Looking for Domestic Tour Package"/>
    </>
  );
};

export default DomesticTourPackage;
