import React from 'react'
import { Link } from 'react-router-dom'
import { MdArrowDropDown } from "react-icons/md";


const NavList = ({ ul_class, li_class , close_click }) => {
   


    return (
        <ul className={`${ul_class}`}>
            <li className={`${li_class}`}> <Link className='block'  onClick={close_click ? close_click : close_click} to="/">Home</Link></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/#our_pricing">Tarrif</a></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/#airport_transfer">Airport Transfer</a></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/accomodation">Accomodation</a></li>

            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/domestic-tour-package">Domestic Tour Package</a></li>
            <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/international-tour-package">International Tour Package</a></li>
            <li className={`${li_class} menu-bar`} > <p className='block'  href="#our_packages">POPUPLAR TOUR PACKAGE</p>
            
            <span><MdArrowDropDown/></span>
            <div className='dropdown'>
                <ul>
                    <li><a href='/darshan-tour-package'>DARSHAN TOUR PACKAGE </a></li>
                    <li><a href='/honeymoon-tour-package'>HONEYMOON TOUR PACKAGE </a></li>
                    <li><a href='/north-tour-package'>NORTH INDIA TOUR PACKAGE</a></li>
                    <li><a href='/south-tour-package'>SOUTH INDIA TOUR PACKAGE</a></li>
                 
                </ul>
            </div>
            </li>
       
            <li className={`${li_class} pr-0 sm:pr-0 md:pr-4`}> <a className='block' onClick={close_click ? close_click : close_click} href="#contact">Contact Us</a></li>
          
            {/* <li className={`${li_class}`}> <a className='block' onClick={close_click ? close_click : close_click} href="/#">Contact</a></li> */}
        </ul>
    )
}

export default NavList