const day=[{days:1},
    {days:2},
    {days:3},
    {days:4},
    {days:5},
    {days:6},
    {days:7},
    {days:8},
    {days:9},
    {days:10},
    {days:11},
    {days:12}
]
export {day}