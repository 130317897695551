import React, { useEffect } from 'react'
import TourPackage from './TourPackage'
import DomesticTourPackage from './DomesticTourPackage'
import InternationalTour from './InternationalTour'
import Dharshan from './Dharshan'

const Packages = ({tour,pack,pack1 ,description , content}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        {tour=="DOMESTIC TOUR PACKAGE"?(<DomesticTourPackage tour={tour} description={description}/>):tour=="INTERNATIONAL TOUR PACKAGE" || tour == "HONEYMOON TOUR PACKAGE" ?(<InternationalTour content={content} tour={tour} pack={pack} description={description}/>)
        :tour == "DARSHAN TOUR PACKAGE"?(<Dharshan tour={tour} pack={pack} pack1={pack1} description={description}/>):(<TourPackage tour={tour} pack={pack} description={description}/>)
        }
    </div>
  )
}

export default Packages