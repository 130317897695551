import React from 'react'
import Form from './Form'

const PageBanner = ({img}) => {
  return (
    <div className='pagebanner flex flex-col justify-center items-center flex-wrap lg:gap-y-8 md:flex-nowrap gap-y-3  md:gap-y-0 gap-x-0  md:gap-x-3' style={{backgroundImage:`url(${img})`}}>
        <div className='sm:w-[100%] md:w-[60%] banner-overlay'>
            <Form/>
        </div>
        
    </div>
  )
}

export default PageBanner